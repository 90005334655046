
.footer{
    background-color: #045625 !important;
    color: #fff;
}
.footer-dark{

    background-color: #2c2c6c!important;
    color: #ebebeb !important;
    transition: background-color 1s ease, color 1s ease;
}


.footer_container{
   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 1rem;
    gap: 1rem;
    background-color: #045625 !important;
    color: #fff;
  
}
.footer_container-dark{
   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 1rem;
    gap: 1rem;
    background-color: #2c2c6c !important;
    color: #ebebeb !important;
    transition: background-color 1s ease, color 1s ease;
  
}


footer p{
    font-size: 14px;
    font-family: 'Courier New', Courier, monospace !important;
}

.table-footer{
  
     width: 100%;
    position: relative;
    bottom: 0;
      
    
  }


  @media (max-width: 600px) {

    footer p{
        font-size: 10px;
        
    }
    /* .footer_container{
flex-direction: column;
gap: 4px;

height: 3.5rem;
    } */

  }
