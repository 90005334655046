.header{

width: 100%;
height: 3.3rem;
position: sticky;
top: 0;
z-index: 5;
background-color: #045625 !important;  

}
.header-dark{
    width: 100%;
height: 3.3rem;
position: sticky;
top: 0;
z-index: 5;
background-color: #041E44 !important;
color: #ebebeb !important;
transition: background-color 1s ease, color 1s ease;

}
.header-bg-dark {
    /* background-color: #045625; */
    background-color: rgba(255, 255, 255, 0.1) !important;
   
}




.table-header{

  position: relative !important;
  top: 0;
  z-index: 2;
  
  
  }

.header-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem ;
    gap: 1rem;
 
    
}

.header-container-dark{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem ;
    gap: 1rem;
    /* background-color: #2c2c6c!important; */
    background-color: #2c2c6c!important;
    color: #ebebeb !important;
    transition: background-color 1s ease, color 1s ease;

}
.header_left .header_logo{
    width: 100px;
    height: 2.6rem;
    border-radius: 5px;
    cursor: pointer;
}
.header_right{
    display: flex;
    gap: 2rem;
    align-items: center;
}

.header_right .logout-btn{
background-color: #045625;
}

.app-name{
    color: #fff;
    font-size: 18px;
    display: inline !important;
 
}
.app{
    display: none;
}

@media (max-width: 600px) {

    
.app-name{
 display: none !important;

 
}

.header_left .header_logo{
    width: 85px;
    height: 2.2rem;
    border-radius: 5px;
    cursor: pointer;
}

}



