.table-template {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color:  #f2f2f2 !important;
  }
.table-template-dark {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-image: url("../../../assets/bg-texture.png");
    background-color:   #1f1f38!important;
     color: #ebebeb !important;
     transition: background-color 1s ease, color 1s ease;
  }
  
  .Header {
    align-self: flex-start; /* Align header to the top */
  
  }
  
  main {
    margin-bottom: auto; /* Push main content to the top of the header */
  }
  .table-content{
    width: 92%;
    margin: 0 auto;
    
  }
  .float-button-group {
    display: flex;
    position: fixed !important;
    bottom: 54px;  
    right: 13px;   
  }


  
  

  