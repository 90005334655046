.primary-btn{
  display: flex;
  align-items: center;
    background-color:#045625;
    color: #fff;
}
.disabled{
  background-color: rgb(247, 227, 227);
  color: gray;
}
.primary-btn-dark{
  display: flex;
  align-items: center;
    background-color:#4db5ff !important;
    color: #fff;
}

.secondary-btn{
    background-color:#fff;
    color:#000 ;
}

.third-btn{
    background-color: #1677ff;
    color: #fff;
}

.signin-btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-weight: bold;
  background-color: #fff;
  color: #686868;
    cursor: pointer;
padding: 1.8rem;
    line-height: 1.2;
   
  
}
/* Custom styles for the Popover */
.custom-popover-content {
    display: flex;
    align-items: center;
    padding: 0.5px;
  
    /* background-color: #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
  }
  
  .avatar-icon {
    background-color: #1890ff;
  }
  
  .user-name {
    margin-left: 8px;
    font-weight: bold;
  }
  .logot{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
background-color:#fff ;
border: transparent;
color: red;
  }
  .logot:hover{
color: #fff;
  }
  .logout-icon{
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {

    .logot {
font-size: 12px;

    }

    .third-btn{
        font-size: 12px;
        width: fit-content;

    }

    .primary-btn{
        width: fit-content;
    }
    .secondary-btn{
        width: fit-content;
    }

  }

