.login-template_container {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto 1fr; /* Auto, 1 fraction, Auto */
  }
  
  .main-content {
    grid-row: 2; /* Occupy the second row */
  }
  
  .footer {
    grid-row: 3; /* Occupy the third row */
  }
  :where(.css-dev-only-do-not-override-17a39f8).ant-row {
    display: inline !important;
    
}