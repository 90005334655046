@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Lato:wght@300&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  font-family: "Poppins", sans-serif !important;
}
body {
  width: 100%;
  height: 100vh;
  background-image: url("./assets/bg-texture.png");
}

.content-dark {
  background-color: #041e44;
  color: #ebebeb !important;
  transition: background-color 1s ease, color 1s ease;
}

.not-dark-mode {
  font-size: 2rem;
  background-color: #fff;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  color: skyblue;
  cursor: pointer;
}

/* Dark Mode Styles */
.dark-mode {
  font-size: 1.4rem;
  background-color: #fff; /* Adjust the dark mode background color as needed */
  color: #000; /* Adjust the dark mode text color as needed */
  border-radius: 50%;

  cursor: pointer;
}
.float-icons-container {
  transition: right 1s ease;
}

/* <=======Form fields=========> */
.user-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem 7rem;
}

.form-field-lg {
  width: 10rem !important;
}

.Form-label-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}
.label {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

/* <------------All page app name------> */
.page-header {
  color: #045625 !important;
  /* background-color:  #1c182f; */
  /* color: #ebebeb; */
  padding: 0.5rem;
}
.page-header-dark {
  color: #fff !important;

  padding: 0.5rem;
}

.nav-link,
a {
  text-decoration: none; /* Remove underline */
}

.window-scroll {
  color: #fff;
  position: fixed;
  top: 0.3rem;
  left: 30rem;
  z-index: 6;
}
.window-scroll-heder {
  background-color: #051328 !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-bottom: 1px solid #fff;
}
.window-scroll-ordered-page {
  position: absolute;
  left: 31rem;
  top: 0.5rem;
}
.data {
  font-weight: normal;
  color: #777;
}
.table-container {
  width: 90%;
  margin: 0 auto;
}

.field-width {
  width: 100%;
}

.gap-sm {
  margin-right: 5px;
}

/* For WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #888888;
  background-image: linear-gradient(0deg, #888888 0%, #b79f9f 100%);
  /* Color of the thumb (the draggable part of the scrollbar) */
  border-radius: 6px; /* Round the corners of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color on hover */
}

@media (max-width: 600px) {
  .label {
    font-size: 12px;
    font-weight: 500;
  }

  Table p {
    font-size: 12px;
  }
  .page-header {
    padding: 0;
  }
}

:where(
    .css-dev-only-do-not-override-17a39f8
  ).ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
  > textarea.ant-input {
  font-size: inherit;
  border: none;
  outline: none;
  font-size: 13px;
  padding: 0.5rem 0.8rem;
  color: #555555 !important;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5rem;
  word-spacing: 1px;
  text-align: justify;
}

/* .cl-white{

  color: #fff;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-pagination .ant-pagination-item a {
color: slateblue;
} */

/* --------antd-pagination------------ */

:where(.css-dev-only-do-not-override-17a39f8).ant-pagination
  .ant-pagination-item
  a {
  display: block;
  padding: 0 6px;
  color: cornflowerblue !important;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link,
:where(.css-dev-only-do-not-override-17a39f8).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: darkseagreen !important;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
:where(.css-dev-only-do-not-override-17a39f8).ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: cornflowerblue !important;
}
