
.scroll-inticator{
    height: 5px;
  width: 100%;
 
    position: fixed;
   top:3.2rem;
    background-color: #045625;
    border-radius: 3px;
    z-index: 10;
}

 .scroll-inticator-progress{
    z-index: 10;
    height: 100%;
    width: 0;
    border-radius: 3px;
    background-color: #059041;
    background-image: linear-gradient(132deg, #059041 0%, #0fd0aa 100%);
    
    
    
    
    
    
    transition: width 0.1s;
}



.scroll-inticator-dark{
    height: 5px;
  width: 100%;
 
    position: fixed;
   top:3.1rem;
    background-color: #041E44;
    border-radius: 3px;
    z-index: 10;
}
 .scroll-inticator-progress-dark{
    z-index: 10;
    height: 100%;
    width: 0;
    border-radius: 3px;
    background-color: #4db5ff ;
    transition: width 0.1s;
}